import React from "react";
import "./Frontpage.css";
import { Button } from "antd";
import bg from '../assets/1.png'

const Frontpage = () => {
  return (
    <div className="front-main">
      <div className="frontpage">
        <div>
        <img src={bg} alt="bg-img" className="bg-img"/>
        </div>
        <div className="front-right">
          
        <h1 className="front-header">CHATIFY</h1>

        <div>
          {" "}
          <a href="/LoginPage" >
            <Button className="start" shape="round" type="primary">
              Get Started
            </Button>
          </a>{" "}
        </div>
        </div>
      </div>
    </div>
  );
};

export default Frontpage;
