import React from "react";
import "./Main.css";
import { useState } from "react";
import { Form, Input, Button, Checkbox, notification } from "antd";
import { LockFilled, MailFilled } from "@ant-design/icons";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { addUsers } from "../utils/url-helper";
import { useNavigate } from "react-router-dom";

//alt shift F

const Main = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone_number, setphone_number] = useState("");

  const [api, contextHolder] = notification.useNotification();

  const navigate = useNavigate();

  function success() {
    notification.success({
      message: "Registration Successful",
      description: "You have been registered successfully.",
    });
  }

  const warning = () => {
    api.open({
      type: "warning",
      message: "Something went wrong",
    });
  };

  const errorPhone = () => {
    api.open({
      type: "error",
      message: "Phone Number already exist",
    });
  };

  const errorEmail = () => {
    api.open({
      type: "error",
      message: "Email already exist",
    });
  };
  const olduser = () => {
    api.open({
      type: "info",
      message: "User already exist",
    });
  };

  const createUser = async (data) => {
    try {
      const { email, password, phone_number } = data;
      const response = await addUsers("/api/users", {
        email,
        password,
        phone_number,
      });

      console.log(response, "response....");
      const responseData = response.data;
      const responseError = responseData.error;
      const responseSuccess = responseData.success;

      if (responseError === "Email already exist") {
        errorEmail();
      }
      if (responseError === "PhnNum already exist") {
        errorPhone();
      }
      if (responseError === "User already exist") {
        olduser();
      }
      if (responseSuccess === "Success") {
        success();
        navigate("/LoginPage");
      }
    } catch (err) {
      console.log(err);
      warning();
    }
  };

  const phoneInput = (value) => {
    setphone_number(value);
  };

  return (
    <section>
      <div className="app-main">
        <div className="app">
          <div className="Main-container">
            <div className="right-main">
              <img src="signUp.jpg" alt="" />
            </div>
            <div className="main-form">
              <Form onFinish={createUser}>
                <div className="box">
                  <div className="signup">Create a new Account</div>
                  <div className="firstLast">
                    <Form.Item
                      name="phone_number"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number",
                        },
                      ]}
                    >
                      <PhoneInput
                        country={"in"}
                        value={phone_number}
                        onChange={phoneInput}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Please input your Email!" },
                      { type: "email", message: "Please input a valid Email" },
                    ]}
                    // hasFeedback
                  >
                    <Input
                      prefix={<MailFilled />}
                      style={{ width: "100%" }}
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                      { min: 5 },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      prefix={<LockFilled />}
                      style={{ width: "100%" }}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    name="acceptTerms"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        message: "Please accept the terms & privacy!",
                      },
                    ]}
                  >
                    <Checkbox>
                      I accept the Terms of Use & Privacy policy
                    </Checkbox>
                  </Form.Item>

                  {contextHolder}

                  <div className="button_container">
                    <Form.Item>
                      <Button
                        className="button"
                        style={{ width: "100%" }}
                        type="primary"
                        htmlType="submit"
                      >
                        Create Account
                      </Button>
                    </Form.Item>
                  </div>
                  <p className="existing">
                    Already have an account?{" "}
                    <a className="anchor" href="/LoginPage" target="blank">
                      Sign in
                    </a>
                  </p>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
