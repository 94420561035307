import React from 'react';

const ChatMain = () => {

  return (
    <div>
      <p>In development..........</p>

    </div>
  );
};

export default ChatMain;
