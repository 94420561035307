import React from "react";
import { Form, Input, Button, notification } from "antd";
import "./Login.css";
import { MailFilled, LockFilled } from "@ant-design/icons";
import {  useState } from "react";
import { verifyPassword } from "../utils/url-helper";

const Login = () => {
  const [email, setEmail] = useState([]);
  const [password, setPassword] = useState([]);

  const [api, contextHolder] = notification.useNotification();

  

  const unknownUser = () => {
    api.open({
      type: "error",
      message: "User not found",
      description: "Signup now",
    });
  };

  const unknownEmail = () => {
    api.open({
      type: "error",
      message: "Email not found",
      description: "Signup now",
    });
  };

  const invalidPassword = () => {
    api.open({
      type: "error",
      message: "Invalid Password",
    });
  };
  
  const successMessage = () => {
    api.open({
      type: "success",
      message: "Login successful",
    });
  };

  const warning = () => {
    api.open({
      type: "warning",
      message: "Something went wrong",
    });
  };


  const verifyUser = async () => {

  
    try {
      // Send a POST request to your backend to verify the user
      const response = await verifyPassword("/api/login", {
        email,
        password,
      });

      console.log(response,"login response===========");

      if (response.data.success === 'Verification Succesfull') {
        
        successMessage();
        window.location.href = "/ChatMain";
        
      } 

      if (response.data.error === "Invalid Password")
      {
        invalidPassword();
      }

      if (response.data.error === "Email not found")
      {
        unknownEmail()
      }

      if (response.data.error === "User not found")
      {
        unknownUser()
      }


    } catch (error) {
      warning()
      console.error("Error verifying user: ", error);
      
    }
  };


  return (
    <>
      {contextHolder}
      <section>
      
        <div className="main_container">
          <div className="box_container">
            <div className="left_grid">
        
              <Form onFinish={verifyUser}>
                <div className="form">
                  <h3>Login</h3>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Please input your Email!" },
                      { type: "email", message: "Please input a valid Email" },
                    ]}
                    hasFeedback
                  >
                    <Input
                   
                      prefix={<MailFilled />}
                      style={{ width: "100%" }}
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                      { min: 5 },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                   
                      prefix={<LockFilled />}
                      style={{ width: "100%" }}
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Item>
                  <a href="/Authentication" target="blank">
                    Forgot Password?
                  </a>
                  <Form.Item>
                    <Button
                   
                      htmlType="submit"
                      className="login_button"
                    >
                      Login
                    </Button>
                  </Form.Item>
                  <p className="signup_now">
                    New user? <a href="/Main">SignUp now</a>
                  </p>
                </div>
              </Form>

            </div>
           
            <div className="right_grid">
              <img src="signin.jpg" alt="" />
            </div>
          </div>
        </div>

        
      </section>
      
    </>
  );
};

export default Login;
