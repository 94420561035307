import axios from "axios";

let baseUrl = "";

if (process.env.NODE_ENV === "production") {
  baseUrl = "https://chatify-service-main.onrender.com";
} else {
  baseUrl = "http://localhost:4001";
}

// Axios GET request function
export const getUsers = (url, params) => {
  return axios.get(`${baseUrl}/api${url}`, { params });
};

// Axios PUT request function
export const editUsers = (url, data) => {
  console.log(data, "data.....");
  return axios.put(`${baseUrl}${url}`, data);
};

// Axios DELETE request function
export const deleteUsers = (url) => {
  return axios.delete(`${baseUrl}${url}`);
};

export const addUsers = (url, params) => {
  return axios.post(`${baseUrl}${url}`, params);
};

export const verifyPassword = (url, params) => {
  return axios.post(`${baseUrl}${url}`, params);
};

export const emailAuth = (url, params) => {
  // console.log(params,"params")
  return axios.post(`${baseUrl}${url}`, params);
};

export const otpAuth = (url, params) => {
  console.log(params, "Email params");
  return axios.post(`${baseUrl}${url}`, params);
};

export const validateOtp = (url, params) => {
  console.log(params, "params");
  return axios.post(`${baseUrl}${url}`, params);
};
