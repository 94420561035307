import {
  getUsers,
  editUsers,
  deleteUsers,
} from "../utils/url-helper";
import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Input, Form } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./Admin.css";
import Admincard from "./Admin_card";

const { confirm } = Modal;

const Admin = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState([]);
  const [updateEmail, setUpdateEmail] = useState(null);
  const [updatePhonenum, setUpdatePhonenum] = useState(null);

  const pageSize = 10;

  const editUser = async (user_id) => {
    try {
      const updatedUser = {};

      if (updateEmail !== null) {
        updatedUser.email = updateEmail;
        updatedUser.user_id = user_id;
      } else {
        updatedUser.email = data.find((user) => user.user_id === user_id).email;
      }

      if (updatePhonenum !== null) {
        updatedUser.phone_number = updatePhonenum;
        updatedUser.user_id = user_id;
      } else {
        updatedUser.phone_number = data.find(
          (user) => user.user_id === user_id
        ).phone_number;
      }

      await editUsers(`/api/users/${user_id}`, updatedUser);

      const updatedUsers = data.map((user) => {
        if (user.user_id === user_id) {
          return { ...data, ...updatedUser };
        }
        return user;
      });

      setData(updatedUsers);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (item) => {
    setEditItem(item);

    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
    editUser(editItem.user_id);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getUsers("/users");
      const jsonData = response.data;
      setData(jsonData);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const handleDelete = (record) => {
    const user_id = record.user_id;
    confirm({
      title: "Confirm Delete",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this user?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteUsers(`/api/users/${user_id}`);
          setData(data.filter((user) => user.user_id !== record.user_id));
        } catch (error) {
          console.error("Error deleting user: ", error);
        }
      },
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "user_id",
      key: "id",
    },
    {
      title: "Phone_Number",
      dataIndex: "phone_number",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Edit user",
      render: (record) => (
        <Button
          className="edit-button"
          type="info"
          onClick={() => handleEdit(record)}
        >
          Edit
        </Button>
      ),
    },
    {
      title: "Delete User",
      render: (record) => (
        <Button
          className="delete-button"
          type="danger"
          onClick={() => handleDelete(record)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="table-view">
        <Table
          dataSource={data}
          columns={columns}
          pagination={data.length >= pageSize ? {} : false}
        />

        <Modal
          title="Edit user info"
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={(_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          )}
        >
          {editItem && (
            <Form key={editItem.user_id}>
              <Form.Item
                label="Email"
                name="email"
                initialValue={editItem.email}
              >
                <Input
                  value={updateEmail}
                  onChange={(e) => setUpdateEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phone_number"
                initialValue={editItem.phone_number}
              >
                <Input onChange={(e) => setUpdatePhonenum(e.target.value)} />
              </Form.Item>
            </Form>
          )}
        </Modal>
      </div>

      <div className="card-view">
        <Admincard
          data={data}
          handledelete={handleDelete}
          handleEdit={handleEdit}
          setEditItem={setEditItem}
        />
      </div>
    </>
  );
};

export default Admin;
