import { Button, Form, Input, notification } from "antd";
import React, { useState } from "react";
import leftImg from "../assets/leftimg.png";
import "./Authentication.css";
import { emailAuth, otpAuth, validateOtp } from "../utils/url-helper";
import { useNavigate } from "react-router-dom";

const Authentication = () => {
  const [showEmail, setShowEmail] = useState(false);
  const [otpVal, setOtpVal] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const errorNotify = () => {
    notification.error({
      message: "No email found",
      description: "Sign up to login",
    });
  };

  const successNotify = () => {
    notification.success({
      message: "Validated successfully",
      description: "OTP sent to the email please check and verify",
    });
  };

  const otpValidNotify = () => {
    notification.success({
      message: "OTP Validated successfully",
      description: "Reset the password now",
    });
  };

  const otpnotValidNotify = () => {
    notification.error({
      message: "OTP is Invalid",
      description: "Please enter the valid OTP",
    });
  };

  const onFinish = async (values) => {
    try {
      const { email } = values;
      const response = await emailAuth("/api/emailAuth", values);
      if (response.status === 200) {
        console.log("Auth is ready");
        successNotify();
        setShowEmail(true);
        otpSubmit(email);
      }
    } catch (err) {
      console.log(err, "Error Occured");
      console.log("Auth not ready");
      errorNotify();
    }
  };

  const emailset = (values) => {
    setEmail(values.email);
    onFinish(values);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setOtpVal(value);
  };

  const otpSubmit = async (emailval) => {
    try {
      const response = await otpAuth("/api/sendOtp", { email: emailval });
      console.log(response, "Response");
    } catch (err) {
      console.error(err, "Error");
    }
  };

  const validateOtpfunction = async () => {
    try {
      const response = await validateOtp("/api/validateOtp", { email, otpVal });
      if (response.status === 200) {
        otpValidNotify();
        navigate("/ResetPassword");
      }
    } catch (err) {
      otpnotValidNotify();
      console.error(err, "error occured");
    }
  };

  return (
    <>
      <div className="main-container-auth">
        <div className="inner-container">
          <div className="left-pane" style={{ width: "50%" }}>
            <img src={leftImg} alt="left" />
          </div>
          <div style={{ width: "50%" }} className="right-pane">
            <div style={{ width: "100%", paddingRight: "3rem" }}>
              <h3 className="headding-text">Confirm your Email</h3>
              {showEmail ? (
                <div style={{ display: "flex", marginLeft: "6rem" }}>
                  <div>
                    <Input
                      placeholder="Enter the OTP"
                      style={{ width: "90%" }}
                      onChange={handleInputChange}
                      maxLength={6}
                      pattern="\d{6}"
                      title="Please enter a 6-digit numeric OTP"
                    />
                  </div>
                  <div className="">
                    <Button
                      onClick={validateOtpfunction}
                      className="submit-button"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <Form
                    name="basic"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={emailset}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Your email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                        {
                          type: "email",
                          message: "Please enter a valid email address!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      wrapperCol={{
                        offset: 8,
                        span: 16,
                      }}
                    >
                      <Button
                        className="form-button"
                        type="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      color: "#620062",
                      gap: "8px",
                    }}
                  >
                    <span>Click here to signup </span>
                    <a href="/Main">Sign Up</a>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="main-container-auth-mobile">
        <div className="auth-container">
          <h3 className="headding-text-mobile">Email Authentication</h3>
          {showEmail ? (
            <div style={{ display: "flex", marginLeft: "15%" }}>
              <div>
                <Input
                  placeholder="Enter the OTP"
                  style={{ width: "90%" }}
                  onChange={handleInputChange}
                  maxLength={6}
                  pattern="\d{6}"
                  title="Please enter a 6-digit numeric OTP"
                />
              </div>
              <div className="">
                <Button onClick={validateOtpfunction} className="submit-button">
                  Submit
                </Button>
              </div>
            </div>
          ) : (
            <>
              <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  maxWidth: 600,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={emailset}
                autoComplete="off"
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                  ]}
                >
                  <Input placeholder="Enter your email" />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 2,
                    span: 12,
                  }}
                >
                  <Button
                    className="form-button"
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
          <div>
            {" "}
            <div className="handwrite-font">You're Chatified!</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Authentication;
