import React from "react";
import "./Reset.css";
import { useState } from "react";
import { Form, Input, Button,notification } from "antd";
import { BiSolidLock } from "react-icons/bi";
import { MailFilled, LockFilled } from "@ant-design/icons";
import { editUsers } from "../utils/url-helper";
import { useNavigate } from "react-router-dom";

const Reset = () => {
  const navigate =  useNavigate();
  const [email, setEmail] = useState(null);
  const [updatedPassword, setUpdatedPassword] = useState(null);

  const [api, contextHolder] = notification.useNotification();

  const unknownEmail = () => {
    api.open({
      type: "error",
      message: "Invalid Email",
    });
  };

  function successMessage() {
    notification.success({
      message: "Password reset Successfully",
      
    });
  }

  const warning = () => {
    api.open({
      type: "warning",
      message: "Something went wrong",
    });
  };


  const updateUser = async (data) => {
    try {
      const{email,ConfirmNewPassword}=data
      
      const response = await editUsers("/api/update", {
        email,password: ConfirmNewPassword
      });

      console.log(response,"update response===========");

     if(response.data.error === "Password reset successfully")
     {
      successMessage()
      // window.location.href = "/LoginPage";
      navigate('/LoginPage')
     }

     if(response.data.error === 'Email not found')
     {
      unknownEmail()
     }


    } catch (error) {
      warning()
      console.error("Error updating user: ", error);
    }
  };

  return (
    <>
    {contextHolder}
    
    <div className="main_box">
      <div className="top_box">
        <div className="reset_box">
          <div className="reset-left">
            <img src="reset.jpg" alt="" />
          </div>
          <div className="reset-form">
            <Form onFinish={updateUser}>
              <div className="icon">
                <BiSolidLock />
              </div>

              <h2>Reset Password</h2>

              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input a Email" },
                  { type: "email", message: "Please input a valid Email" },
                ]}
                hasFeedback
              >
                <Input
                  prefix={<MailFilled />}
                  placeholder="Confirm your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>

              <Form.Item
  name="NewPassword"
  rules={[
    { required: true, message: "Please input your new Password" },
    { min: 5, message: "Password must be at least 5 characters" },
  ]}
>
  <Input.Password
    prefix={<LockFilled />}
    placeholder="New Password"
    value={updatedPassword}
    onChange={(e) => setUpdatedPassword(e.target.value)}
  />
</Form.Item>

<Form.Item
  name="ConfirmNewPassword"
  dependencies={["NewPassword"]}
  rules={[
    { required: true, message: "This field is required" },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue("NewPassword") === value) {
          return Promise.resolve();
        }
        return Promise.reject("The two passwords that you entered do not match");
      },
    }),
  ]}
>
  <Input.Password
    prefix={<LockFilled />}
    placeholder="Confirm new Password"
    
  />
</Form.Item>


              <Form.Item>
                <a href="/LoginPage">
                  {" "}
                  <Button htmlType="submit" className="reset_button">
                    {" "}
                    Reset Password
                  </Button>
                </a>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Reset;
