import React from "react";
import { Card, Button } from "antd";

const Admincard = (props) => {
  const { data, handledelete, handleEdit, setEditItem } = props;

  
  const handlecardedit=(value)=>
  {
    
    handleEdit();
    setEditItem(value);

  }

  const handlecarddelete =(item)=>
  {
    handledelete(item)
  }

  const card = data.map((item) => (
    <Card
      className="ant-card"
      title={item.user_id}
      style={{ background: "white" }}
    >
      <p style={{ fontWeight: "bold" }}>Phone Number: {item.phone_number}</p>
      <p style={{ fontStyle: "italic" }}>Email ID: {item.email}</p>
      <p>{item.description}</p>
      <Button className="edit-button" type="info" onClick={()=>handlecardedit(item)}>
        Edit
      </Button>
      <Button className="delete-button" type="danger" onClick={()=>handlecarddelete(item)}>
        Delete
      </Button>
    </Card>
  ));

  return <div className="admin-card">{card}</div>;
};

export default Admincard;
