import FrontPage from "./Front-page/Frontpage";
import Main from "./Sign-up/Main";
import LoginPage from "./Login-page/Login";
import ResetPw from "./Reset-pw/Reset";
import Admin from "./Admin/Admin";
import Authentication from "./Authentication/Authentication";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ChatMain from "./Chat-main/ChatMain";

function Index() {
  return (
    <Router>
      <>
        <Routes>
          <Route path="/" element={<FrontPage />} />
          <Route path="/Main" element={<Main />} />
          <Route path="/LoginPage" element={<LoginPage />} />
          <Route path="/ResetPassword" element={<ResetPw />} />
          <Route path="/Admin" element={<Admin />} />
          <Route path="/Authentication" element={<Authentication />} />
          <Route path="/ChatMain" element={<ChatMain />} />
        </Routes>
      </>
    </Router>
  );
}

export default Index;
